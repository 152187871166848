import logo from './logo.svg';
import './App.css';
import '@radix-ui/themes/styles.css';
import { Flex, Text, Button, Box, Container, Heading, Card, Callout, Slider } from '@radix-ui/themes';
import toast, { Toaster } from 'react-hot-toast';

import { useEffect, useState } from 'react';
import { Sparkles, Info } from 'lucide-react';
import { motion } from "framer-motion";
import Cookies from 'universal-cookie';

function App() {

  const cookies = new Cookies(null, { path: '/' });

  const [employees, setEmployees] = useState(100);
  const handleSliderChange = (event, setter) => {
    const value = event.target ? event.target.value : event;
    setter(Number(value));
  };

  const [costAnnualEmployees, setCostAnnualEmployees] = useState(30000);
  const [timeSaved, setTimeSaved] = useState(105);
  const [costGemini, setCostGemini] = useState(30);
  const [remiseCommercial, setRemiseCommercial] = useState(20);
  const [nmbLicences, setNmbLicences] = useState(15);
  const [hiddenClass, setHiddenClass] = useState('hd-comp');
  const pourcentageReductionTime = 20;

  const purcentageAdoption = (nmbLicences / employees * 100).toFixed(2);
  const economyFullTime=((nmbLicences*timeSaved*45)/(60*7*5*45)).toFixed(2);
  const creationValue=(economyFullTime*(pourcentageReductionTime/100)).toFixed(2);
  const creationValueTotalEuros=(costAnnualEmployees*creationValue);
  const investGemini=((costGemini*(1-remiseCommercial/100))*nmbLicences*12);
  const ROI=(creationValueTotalEuros/investGemini*100).toFixed(2);

  const checkAlreadyUnlocked=() => {
    if(cookies.get('unlocked_content')==true) {
      setHiddenClass('');
    }
  }
  useEffect(() => {
    checkAlreadyUnlocked();
  });

  const unblockContent = () => {
    // Getthing the email input value
    const email = document.querySelector('input[type="email"]').value;
    const emailTesting= new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$');
    if (!emailTesting.test(email)) {
      return toast.error('Veuillez renseigner un email valide');
    }
    let loader=toast.loading('Déverrouillage en cours ...');
    fetch('https://numericoach.fr/connector/simulator.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        contactEmail: email
      }),
    })
      .then((response) => response)
      .then((data) => {
        toast.success('Merci, vous avez débloqué l\'accès à votre ROI');
        toast.dismiss(loader);
        setHiddenClass('hd-show');
        cookies.set('unlocked_content', 'true');
      })
      .catch((error) => {
        toast.error('Mince, il y a eu une erreur, veuillez réessayer plus tard');
        toast.dismiss(loader);
        console.error('Error:', error);
      });
  }
  return (
    <div className="App">
      <Flex align='center' justify='center' direction='column' gap='0' className='mt-4'>     

      <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.35, duration: 0.4 }}
      >
        <Box style={{ background: 'var(--gray-a2)', borderRadius: 'var(--radius-3)' }} maxWidth="950px" py="5">
          <Card asChild maxWidth="950px" style={{boxShadow: "var(--shadow-1)"}}>
            <Box py="4">
              <a href="https://numericoach.fr/" target="_blank"><img src="/numericoach.png" alt="Numericoach" style={{ width: '100%', maxWidth: '150px', margin: '0px 0px 20px 0px', display: 'block' }}/></a>
              <Callout.Root size="1" color="gray" variant="soft">
                <Callout.Icon>
                  <Info/>
                </Callout.Icon>
                <Callout.Text>
                  <b>Ce simulateur à été conçu par l'équipe Numericoach</b><br/>
                  Cette estimation est à titre indicative, elle restitue toutefois un ROI moyen constaté parmi les benchmarks récents.
                  <br/><b><small>Des valeurs moyennes par défaut sont indiquées pour vous aider, à vous de rentrer les données (curseur à faire glisser ou données à saisir manuellement).</small></b>
                </Callout.Text>
              </Callout.Root>
              <motion.div 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.35, duration: 0.4 }}
              >
                <Callout.Root color="green" mt="5" size="1">
                    <Callout.Icon>

                    </Callout.Icon>
                    <Callout.Text>
                    Depuis le 1er octobre 2024, il est <b>possible de prendre vos licences Gemini ailleurs que votre revendeur habituel</b>. N’hésitez pas à nous solliciter pour un devis personnalisé. <b>Numericoach s’engage à fournir les meilleurs tarifs du marché</b>. Du contenu (supports, guide pratiques, vidéos) sera en plus offert en mettant vos licences Gemini chez Numericoach.
                    </Callout.Text>
                </Callout.Root>
              </motion.div>
              <img src="/google-gemini.png" alt="Google Gemini" style={{ width: '100%', maxWidth: '150px', margin: '30px auto', display: 'block' }}/>
              <Heading weight="bold" as="h1" mb="2" mt="5">Simulateur de retour sur investissement Gemini for Workspace</Heading>
              <Text size="2" as="div" mt="0">
                  Nombre total de collaborateurs dans votre entreprise : <b>{employees}</b>
              </Text>
              <Flex gap="3" mt="3" className="items-center">
                <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" style={{maxWidth: "100px"}} type="number" value={employees} onChange={(e)=> handleSliderChange(e, setEmployees)}/>
                <Slider value={[employees]} onValueChange={(e)=> handleSliderChange(e, setEmployees)} max="10000"/>
              </Flex>

              <Text size="2" as="div" mt="5">
                  Coût moyen annuel complet par collaborateur (salaires et charges) : <b>{costAnnualEmployees}</b> €
              </Text>
              <Text size="1" as="p" className="text-gray-500">
              Si vous n’avez pas cette information, voir avec votre service RH / paie, sinon nous vous recommandons pour un salaire net MENSUEL de multiplier par 22 afin d’avoir le coût ANNUEL du salaire chargé (charges salariales et patronales)
              </Text>
              <Flex gap="3" mt="3">
                <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" type="number" value={costAnnualEmployees} onChange={(e)=> handleSliderChange(e, setCostAnnualEmployees)}/>
              </Flex>

              <Text size="2" as="div" mt="5">
                  Nombre moyen de minutes économisées par semaine grâce à l'IA générative Gemini : <b>{timeSaved}</b> min*
              </Text>
              <Text size="1" className="text-xs text-gray-500" as="p">* Grâce à l'IA intégrée dans les applications les plus populaires, notamment Gmail, Docs et Drive, les utilisateurs économisent en moyenne 105 minutes par semaine, selon une récente étude réalisée auprès des clients professionnels.</Text>
              <Text size="1" className="text-xs text-gray-500" as="p"><a href="https://workspace.google.com/blog/product-announcements/google-workspace-extends-gemini-benefits-to-more-customers?utm_term=-&hl=en&utm_campaign=FY24-Q3-amer-googleworkspace_workspaceblog_Product-Education&utm_medium=unpaidsoc&utm_content=-&utm_source=linkedin" target="_blank" className="underline text-blue-700">D'après une analyse interne de Google des données d'enquête</a> issues des projets pilotes Gemini pour Google Workspace terminés.. Les données ont été agrégées en juillet 2024 auprès de 18 entreprises clientes du monde entier, réparties en Amérique du Nord, en Europe, au Moyen-Orient et en Asie-Pacifique. Examen de plus de 3 200 réponses d'employés issus d'un large éventail de secteurs, notamment la fabrication, la santé et les sciences de la vie, la vente au détail, l'énergie, les services financiers, les logiciels et la technologie.</Text>
              <Flex gap="3" mt="3">
                <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" type="number" value={timeSaved} onChange={(e)=> handleSliderChange(e, setTimeSaved)}/>
              </Flex>

              <Text size="2" as="div" mt="5">
                  Nombre nécessaire de licences Gemini for Workspace dans votre entreprise : <b>{nmbLicences}</b>
              </Text>
              <Text size="1" as="p" className="text-gray-500">
              Doit être égal ou inférieur au nombre de collaborateurs dans votre entreprise
              </Text>

              <Flex gap="3" mt="3" className="items-center">
                <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" style={{maxWidth: "100px"}} type="number" value={nmbLicences} onChange={(e)=> handleSliderChange(e, setNmbLicences)}/>
                <Slider value={[nmbLicences]} onValueChange={(e)=> handleSliderChange(e, setNmbLicences)} max={[employees]}/>
              </Flex>

              <Text size="2" as="div" mt="5">
              Coût unitaire mensuel (HT et en euros) d’une <a href="https://workspace.google.com/intl/fr/solutions/ai/#plan" target="_blank" className="underline">licence Gemini</a> : <b>{costGemini}</b> €
              </Text>
              <Text size="1" as="p" className="text-gray-500">
              Les tarifs peuvent varier selon l’engagement (flex VS commit), selon le type de licences (Business ou Enterprise), et selon les remises additionnelles de Google ou du revendeur Numericoach.
              </Text>
              <Text size="1" as="p" className="text-gray-500">
              Gemini Business (engagement d'un an) : 19,20 euros HT par mois<br/>
              Gemini Enterprise (engagement d'un an) 28,86 euros HT par mois
              </Text>
              <Flex gap="3" mt="3">
                <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" type="number" value={costGemini} onChange={(e)=> handleSliderChange(e, setCostGemini)}/>
              </Flex>

              <Text size="2" as="div" mt="5">
              Remise commerciale sur les licences Gemini <small>(octroyée par Google et le revendeur Numericoach)</small> : <b>{remiseCommercial}</b> (en % de remise)
              </Text>
              <Text size="1" as="p" className="text-gray-500">
              Pour connaitre les remises disponibles, merci de contacter Thierry Vanoffe (<a href="mailto:thierry@numericoach.fr" className="underline">thierry@numericoach.fr</a>)<br/>
              Dans tous les cas, pour les remises, nous nous alignons sur la meilleure offre que vous avez, avec du contenu et du service offerts en plus.
              </Text>
              <Flex gap="3" mt="3">
                <Slider value={[remiseCommercial]} onValueChange={(e)=> handleSliderChange(e, setRemiseCommercial)} max="100"/>
              </Flex>
              <Heading weight="bold" as="h1" mb="2" mt="5" align="center" size="5">Votre retour sur investissement</Heading>
              {hiddenClass === 'hd-comp' ?
              <Box>
                <Card maxWidth="950px" style={{boxShadow: "var(--shadow-1)", position: "absolute", zIndex: 9999, left: "25%"}} className="absolute mt-5">
                  <Text as="p" weight="bold">Débloquez l'accès à votre ROI</Text>
                  <Text size="1" as="p" className="text-gray-500">Renseignez juste votre email ci-dessous afin de débloquer l'accès à votre ROI</Text>
                  <input type="email" onKeyDown={(e) => {
                    if(e.key=='Enter') {
                      unblockContent();
                    }
                  }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 mt-4" placeholder="Votre email"/>
                  <Button variant="surface" style={{marginTop: "14px"}} onClick={unblockContent}>Débloquez votre accès instantanément</Button>
                </Card>
              </Box>
              : ''}
              <Box className={hiddenClass}>
                <Text size="2" mt="3" as="div">Pourcentage d'attribution d'utilisateurs ayant Gemini par rapport au nombre total de collaborateurs de l'entreprise</Text>
                <Text weight="bold">{purcentageAdoption} %</Text>

                <Text size="2" mt="3" as="div">Equivalent temps plein de votre utilisation de l’IA Gemini *</Text>
                <Text size="1" as="p" className="text-gray-500">
                * Nombre d'employés à temps plein dont le travail aurait été nécessaire pour accomplir les mêmes tâches sans l'outil sur une base d’un 35H
                </Text>
                <Text weight="bold">{economyFullTime}</Text>

                <Text size="2" mt="3" as="div">Création de valeur sur l'équivalent temps plein gagné*</Text>
                <Text size="1" as="p" className="text-gray-500">
                * Nombre d'employés à temps plein dont le travail aurait été nécessaire pour atteindre le même niveau de production sans l'outil
                </Text>
                <Text weight="bold">{creationValue}</Text>


                <Text size="4" weight="bold" mt="3" as="div">Création de valeur en € grâce à l'IA Gemini</Text>
                <Text weight="bold" size="6">
                  {new Intl.NumberFormat('fr-FR', { maximumSignificantDigits: 3 }).format(
                    creationValueTotalEuros,
                  )} €</Text>

                <Text size="4" weight="bold" mt="3" as="div">Investissement annuel en licences Gemini</Text>
                <Text weight="bold" size="4">
                  {new Intl.NumberFormat('fr-FR', { maximumSignificantDigits: 3 }).format(
                    investGemini,
                  )} € HT</Text>

                <Text size="4" weight="bold" mt="3" as="div">ROI</Text>
                <Text weight="bold" size="6">{ROI} % *</Text>
                <Text className="text-gray-600" size="1" as="p" mt="3">* Le <b>ROI (retour sur investissement)</b> permet de déterminer la valeur dégagée par votre investissement. Ici, nous calculons ce retour en prenant la valeur finale (valeur créée par l’utilisation de Gemini) par rapport à la valeur initiale (le coût des licences). <b>Si ce pourcentage est supérieur à 100%, alors l’investissement est rentable</b>, sinon, l’investissement vous aura en réalité fait perdre de l’argent. Plus le pourcentage est élevé, plus l’investissement est rentable !</Text>
              </Box>
              

            </Box>
          </Card>
        </Box>
        </motion.div>
      </Flex>
      <Toaster />
    </div>
  );
}

export default App;
